import * as React from "react"
import Layout from "../components/layout"
import FAQs from "../components/Index/FAQs"






const faqs = () => {
  return (
  <Layout>
   <FAQs />
  </Layout>
)
};
export default faqs;
