import React from "react"
import "../../assets/css/style.css"
import "../../assets/css/custom.css"
import "../../assets/css/vendor/vendor.min.css"
import "../../assets/css/plugins/plugins.min.css"

const FAQs = () => {
  return (
    <div id="main-wrapper">
      {/* <div className="breadcrumb-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumb_box text-center">
                <h1 className="breadcrumb-title">FAQs </h1>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="faq-banner-wrap banner-space faq-bg">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="faq-banner-content text-center">
                <h1 className="mb-15 text-white">FAQs</h1>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* <!-- breadcrumb-area end --> */}
      <div className="site-wrapper-reveal">
        <div className="accordion-wrapper section-space--ptb_80">
          <div className="container">
            {/* className="faq-body" */}
            <div id="faq" className="">

              {/* <div className="faq-header">
                <h3 className="faq-title">FAQ's</h3>
                <div className="seperator"></div>
              </div> */}

              <div className="faq-list">
                <div>
                  <details open>
                    <summary
                      className="faqs-heading"
                      title="How can I pay for my appointment?"
                    >
                      How can we help your business?
                    </summary>
                    <p className="faq-content">
                      Through the collaboration with customers in discussing
                      needs and demands, we're able to attain mutual
                      understanding, gain customer trust to offer the
                      appropriate advice, and bring about suggestions on
                      suitable technology to transform your business.
                    </p>
                  </details>
                </div>
                <div>
                  <details>
                    <summary
                      className="faqs-heading"
                      title="What can I expect at my first consultation?"
                    >
                      What are the advantages of Aamanto Technologies?
                    </summary>
                    <p className="faq-content">
                      Aamanto takes into consideration every little detail to
                      make sure the system runs smoothly and responsively.
                      Aamanto’s dedicated team is professional in cloud-based
                      solutions. Our team is equipped with future technologies
                      and creates cutting-edge solutions for current and future
                      markets.
                    </p>
                  </details>
                </div>
                <div>
                  <details>
                    <summary
                      className="faqs-heading"
                      title="What are your opening hours?"
                    >
                      How working process is simplified?
                    </summary>
                    <p className="faq-content">
                      We reduce redundant complex calculations and lengthy
                      erroneous code texts with simpler ones to ensure Aamanto
                      would run seamlessly and the design is reserved in its
                      best form when viewed from a wide range of mobile devices
                      & browsers. We have a team of UX/CX designers to ensure
                      the overall customer design and customer experience are
                      kept in mind when designing solutions for our customers.
                      We work under a customized Agile development model to
                      ensure rapid development and speed to market the product
                      for our clients.
                    </p>
                  </details>
                </div>
                <div>
                  <details>
                    <summary
                      className="faqs-heading"
                      title="Do I need a referral?"
                    >
                      Product Engineering & Services
                    </summary>
                    <p className="faq-content">
                      Our team design, architect, develop and deliver
                      applications in cloud platforms. We work with Google GCP,
                      Amazon AWS, and other cloud technologies. Our team is
                      experienced in working on all cloud-based solutions. We
                      have a solution team is available to ensure your solution
                      is covered from all aspects of risk and security.
                    </p>
                  </details>
                </div>
              </div>

            </div>
          </div>
          {/* <!--====================  Accordion area  ====================--> */}
        </div>
      </div>
    </div>
  )
}

export default FAQs
